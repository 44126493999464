import { Common } from '../consts/Common';

export default {};

// 0: "非讯达" ,600: "X讯达", 601: "车讯达", 602: "房讯达"; 603: "家讯达"
export const isXunda = (value) => {
  const code = window.pageProps.xundaOuterCode;
  if (!/^60\d$/.test(code)) return false;
  if (value === undefined) return true;
  if (typeof value !== 'string') return false;
  const codeMap = new Map([
    [601, ['cxd', '车', '车讯达']],
    [602, ['fxd', 'eft', '房', '鹅房通']],
    [603, ['jxd', '家', '家讯达']],
  ]);
  const typeMap = {};
  for (const [key, value] of codeMap) {
    value.forEach((name) => {
      typeMap[name] = key;
    });
  }
  return typeMap[value] === code;
};

/**
 * 判断当前登录的账号是否是房讯达账号
 * @returns boolean
 */
export const judgeIsFxd = () => isXunda('fxd');

export const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

/**
 * 判断当前页面是否为TMC内嵌
 * @returns boolean
 */
export const judgeIsFromTMC = () => getQueryVariable('from') === 'tmc';

export const UrlTool = {
  gotoIndex: () => {
    window.location.href = '/';
  },
  gotoAdvertiser: () => {
    const url = UrlTool.getFullPara();
    window.location.href = url;
  },
  gotoSecondLogin: () => {
    const url = UrlTool.getFullPara();
    window.location.href = `${url}#/secondLogin`;
  },
  checkIfNeedSecondLogin: () => {
    const dest = UrlTool.getParaValue('dest');
    if (dest === 'third') {
      return false;
    }
    return true;
  },
  getFullPara: () => {
    const from = UrlTool.getParaValue('from');
    const dest = UrlTool.getParaValue('dest');
    let url = `/advertiser/${window.pageProps.uid}`;
    if (from.length > 0) {
      url = `${url}?from=${UrlTool.getParaValue('from')}`;
    }
    if (from.length > 0 && dest.length > 0) {
      url = `${url}&dest=${UrlTool.getParaValue('dest')}`;
    } else if (dest.length > 0) {
      url = `${url}?dest=${UrlTool.getParaValue('dest')}`;
    }
    return url;
  },
  getParaValue: (para) => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === para) {
        return pair[1];
      }
    }
    return '';
  },
  getParaValueFromUrl: (url, para) => {
    const query = url.split('?')[1];
    if (!query) return '';
    const vars = query.split('&');
    if (!vars) return '';
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair[0] === para) {
        return pair[1];
      }
    }
    return '';
  },
};
// 通用fetch下载
export const commonFileDownload = (url, params, onComplete, onCheck) => {
  // 1. 构建fetch请求api
  const buildFetchApi = (url, params) => {
    const csrfToken = window.pageProps.token;
    const v2CsrfToken = window.pageProps.v2csrf;
    const v1CsrfTokenString = csrfToken ? `&csrfToken=${csrfToken}` : '';
    const v2CsrfTokenString = v2CsrfToken ? `&_csrf=${v2CsrfToken}` : '';
    return  `${url}${params}${v1CsrfTokenString}${v2CsrfTokenString}`;
  };

  // 2.构建请求url和参数
  const fileInfoApi = buildFetchApi(Common.LEADS_DOWNLOAD_FILEINFO, params);
  const downLoadApi = buildFetchApi(url, params);
  const fetchParams = {
    method: 'get',
    contentType: 'application/json;charset=utf-8',
    timeout: 180000,
  };

  // 3.下载请求
  const downLoadFile = (downLoadApi, fetchParams, fileName) => fetch(downLoadApi, fetchParams)
    .then((d) => {
      if (d.status !== 200) {
        window.alert('下载文件失败');
        return;
      }
      if (!fileName) {
        fileName = d.headers.get('content-disposition');
        const fnr = fileName.match(/([^=]+\.csv)$/);
        if (fnr) fileName = fnr[0];
        console.debug('commonFileDownload from headers, fileName: ', fileName);
      }
      const type = d.headers.get('content-type');
      if (type === 'application/x-download') {
        return d.blob();
      }
      return d.json();
    })
    .then((data) => {
      console.debug('commonFileDownload, data: ', data);
      if (data instanceof Blob) {
        const _url = window.URL.createObjectURL(data);
        // 下载完成回调
        if (onComplete({ fileName, url: _url })) {
          const a = document.createElement('a');
          a.href = _url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else if (data?.code !== 0) {
        window.alert(data?.msg);
      }
    });

  // 4.请求前获取要下载的文件信息
  // onCheck方法不传直接下载
  !onCheck ? downLoadFile(downLoadApi, fetchParams) : fetch(fileInfoApi, fetchParams)
    .then((i) => {
      if (i.status !== 200) {
        window.alert('获取下载文件信息失败');
        return;
      }
      return i.json();
    })
    .then(({ code = 0, msg = '', data: fileInfo = {} }) => {
      if (code !== 0) {
        window.alert(msg);
        return;
      }
      const {
        originalFileName = '',
        fileLineNumber = 0,
      } = fileInfo;
      onCheck({ fileName: originalFileName, fileLine: fileLineNumber });
      downLoadFile(downLoadApi, fetchParams, originalFileName);
    });
};


export const commonFileDownloadByPost = (url, params, onComplete, onCheck) => {
  // 1. 构建fetch请求api
  const buildFetchApi = (url) => {
    const csrfToken = window.pageProps.v2csrf;
    const v2CsrfToken = window.pageProps.v2csrf;
    const v1CsrfTokenString = csrfToken ? `csrfToken=${csrfToken}` : '';
    const v2CsrfTokenString = v2CsrfToken ? `&_csrf=${v2CsrfToken}` : '';
    return  `${url}?${v1CsrfTokenString}${v2CsrfTokenString}`;
  };

  // 2.构建请求url和参数
  const fileInfoApi = buildFetchApi(Common.LEADS_DOWNLOAD_FILEINFO);
  const downLoadApi = buildFetchApi(url);
  const fetchParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 180000,
    body: JSON.stringify(params),
  };

  // 3.下载请求
  const downLoadFile = (downLoadApi, fetchParams, fileName) => fetch(downLoadApi, fetchParams)
    .then((d) => {
      if (d.status !== 200) {
        window.alert('下载文件失败');
        return;
      }
      if (!fileName) {
        fileName = d.headers.get('content-disposition');
        const fnr = fileName.match(/([^=]+\.csv)$/);
        if (fnr) fileName = fnr[0];
        console.debug('commonFileDownload from headers, fileName: ', fileName);
      }
      const type = d.headers.get('content-type');
      if (type === 'application/x-download') {
        return d.blob();
      }
      return d.json();
    })
    .then((data) => {
      console.debug('commonFileDownload, data: ', data);
      if (data instanceof Blob) {
        const _url = window.URL.createObjectURL(data);
        // 下载完成回调
        if (onComplete({ fileName, url: _url })) {
          const a = document.createElement('a');
          a.href = _url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else if (data?.code !== 0) {
        window.alert(data?.msg);
      }
    });

  // 4.请求前获取要下载的文件信息
  // onCheck方法不传直接下载
  !onCheck ? downLoadFile(downLoadApi, fetchParams) : fetch(fileInfoApi, fetchParams)
    .then((i) => {
      if (i.status !== 200) {
        window.alert('获取下载文件信息失败');
        return;
      }
      return i.json();
    })
    .then(({ code = 0, msg = '', data: fileInfo = {} }) => {
      if (code !== 0) {
        window.alert(msg);
        return;
      }
      const {
        originalFileName = '',
        fileLineNumber = 0,
      } = fileInfo;
      onCheck({ fileName: originalFileName, fileLine: fileLineNumber });
      downLoadFile(downLoadApi, fetchParams, originalFileName);
    });
};

export const commonOaFileDownloadByPost = (url, params, onComplete) => {
  const fetchParams = {
    method: 'POST',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
    },
    timeout: 180000,
    body: JSON.stringify(params),
  };
  // 下载请求
  const downLoadFile = (downLoadApi, fetchParams, fileName) => fetch(downLoadApi, fetchParams)
    .then((d) => {
      if (d.status !== 200) {
        window.alert('下载文件失败');
        return;
      }
      if (!fileName) {
        fileName = d.headers.get('content-disposition');
        const fnr = fileName.match(/([^=]+\.csv)$/);
        if (fnr) fileName = fnr[0];
        console.debug('commonOaFileDownloadByPost from headers, fileName: ', fileName);
      }
      const type = d.headers.get('content-type');
      if (type === 'application/x-download') {
        return d.blob();
      }
      return d.json();
    })
    .then((data) => {
      console.debug('commonOaFileDownloadByPost, data: ', data);
      if (data instanceof Blob) {
        const _url = window.URL.createObjectURL(data);
        // 下载完成回调
        if (onComplete({ fileName, url: _url })) {
          const a = document.createElement('a');
          a.href = _url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
        }
      } else if (data?.code !== 0) {
        window.alert(data?.msg);
      }
    });
  downLoadFile(url, fetchParams);
};
