import { LeadsManageAction } from '../actions/LeadsManageAction';

/**
 * 线索管理平台
 * 1、单条线索更新事件，传递待更新的leadsId
 * @param state
 * @param action
 * @returns {*}
 */
export default (state = {}, action) => {
  switch (action.type) {
    case LeadsManageAction.UPDATE_SINGLE_LEADS:
      return Object.assign({}, state, {
        targetLeadsId: action.targetLeadsId,
        updateType: action.updateType,
        targetValue: action.targetValue,
      });
    default:
      return state;
  }
};
