import { GlobalStateAction } from '../actions/GlobalStateAction';

const defaultState = {
  accountList: [{
    accountId: window.pageProps ? window.pageProps.uid : '',
    accountName: window.pageProps ? window.pageProps.companyName : '',
    accountRemark: window.pageProps ? window.pageProps.accountRemark : '',
  }],
  status: 'init',
};

export default (state = defaultState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GlobalStateAction.SET_ACCOUNT_LIST:
      return {
        ...state,
        accountList: payload.accountList || [],
        status: 'received',
      };
    case GlobalStateAction.ACCOUNT_LIST_SEARCHING:
      return {
        ...state,
        accountList: [],
        status: 'searching',
      };
    default:
      return state;
  }
};
