/**
 * 针对全局状态，如：
 *
 * 1、loading
 *
 * @param state
 * @param action
 */
import { GlobalStateAction } from '../actions/GlobalStateAction';
import _ from 'lodash';

const defaultState = {
  loading: false,
  // 行业状态相关
  industry: 1,
  industryStateTree: [],
  commonStateTree: [],
  stateQueryOptions: [],
  stateListTips: [],
  // 从接口获取的原始数据
  sourceIndustry: [],
  sourceCommon: [],
};

const getCurrentStateTree = (arr) => {
  if (!Array.isArray(arr)) return [];
  const int2floatStr = (n) => `${n}.0`;
  // 注意，value不能直接用整数，会触发奇怪的bug！！！
  const tree = [{ desc: '跟进中', value: int2floatStr(3) }];
  for (const item of arr) {
    if (parseInt(item.value, 10) < 0) continue;
    if (!Array.isArray(item.options)) {
      tree.push({
        desc: item.label,
        value: int2floatStr(item.value),
      });
      continue;
    } else if (['无效', '战败'].includes(item.label)) {
      tree.push({
        desc: item.label,
        value: '',
        options: item.options.map((child) => ({
          desc: child.label,
          value: int2floatStr(child.value),
          deprecated: child?.deprecated,
        })),
      });
    } else {
      item.options.forEach((child) => {
        tree.push({
          desc: child.label,
          value: int2floatStr(child.value),
          deprecated: child?.deprecated,
        });
      });
    }
  }
  return tree;
};

const getTips = (tips) => {
  if (!Array.isArray(tips)) return;
  let arr = [...tips];
  arr = arr.map((item) => item.children || item);
  arr = _.flatten(arr);
  arr = arr.filter((item) => typeof item.statusDesc === 'string');
  return arr.map((item) => ({
    enumValue: item.statusName,
    enumDetail: item.statusDesc,
    deprecated: item?.deprecated,
  }));
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case GlobalStateAction.SHOW_LOADING:
      return Object.assign({}, state, { showLoading: true });

    case GlobalStateAction.HIDE_LOADING:
      return Object.assign({}, state, { showLoading: false });

    case GlobalStateAction.SHOW_TIPS:
      return Object.assign({}, state, {
        tips: {
          show: true,
          callBack: action.callBack,
          message: action.message,
          type: action.tipsType,
          time: action.time,
        },
      });
    case GlobalStateAction.HIDE_TIPS:
      return Object.assign({}, state, {
        tips: {},
      });

    case GlobalStateAction.SET_INDUSTRY:
      return { ...state, industry: action.payload };
    case GlobalStateAction.SET_INDUSTRY_DATA: {
      const { payload } = action;
      const newState = { ...state };
      if (payload.industry) newState.industry = payload.industry;
      if (Array.isArray(payload.industryTree)) newState.industryTree = payload.industryTree;
      if (Array.isArray(payload.industryQueryList)) newState.industryQueryList = payload.industryQueryList;
      return newState;
    }
    case GlobalStateAction.INDUSTRY_SWITCH: {
      const tree = action.payload === 2 ? state.industryStateTree : state.commonStateTree;
      const currentStateTree = getCurrentStateTree(tree);
      const stateTips = getTips(action.payload === 2 ? state.sourceIndustry : state.sourceCommon);
      return { ...state, currentStateTree, stateTips, industry: action.payload };
    }
    case GlobalStateAction.INDUSTRY_INIT: {
      const { payload } = action;
      let industry = 1;
      // 当前可用的状态树, 对于可选状态和层级有特殊处理
      let currentStateTree = [];
      // 行业的状态树
      let industryStateTree = [];
      // 通用的状态树
      let commonStateTree = [];
      /**
       * 用于筛选的下拉菜单结构，需要分为3组
       * @type {{
       *   label: string,
       *   value: number | string,
       *   desc: string,
       *   _desc: string,
       *   isTitle: true | undefined,
       * }[]}
       */
      const stateQueryOptions = [
        { value: -1, label: '待判定', isTitle: true },
      ];

      const generateOption = (arr) => {
        if (!Array.isArray(arr)) return [];
        const optionTree = [];
        const wait = [];
        arr.forEach((item) => {
          if (!Array.isArray(item.children)) return;
          if (item.children.length === 1 && [1, 2, 3].includes(item.children[0].statusId)) {
            const child = item.children[0];
            wait.push({
              value: child.statusId,
              label: child.statusName,
              desc: child.statusName,
              _desc: child.statusDesc,
              deprecated: child?.deprecated,
            });
          } else {
            optionTree.push({
              value: item.statusId,
              label: item.statusName,
              desc: item.statusName,
              options: item.children.map((child) => ({
                value: child.statusId,
                label: child.statusName,
                desc: child.statusName,
                _desc: child.statusDesc,
                deprecated: child?.deprecated,
              })),
            });
          }
        });
        if (wait.length > 0) {
          optionTree.unshift({
            value: -999,
            label: '待判定',
            desc: '待判定',
            options: wait,
          });
        }
        return optionTree;
      };

      industry = payload.leadsStatusSystemOption || 1;
      commonStateTree = generateOption(payload.standard || []);
      industryStateTree = generateOption(payload.industry || []);
      currentStateTree = getCurrentStateTree(industry === 2 ? industryStateTree : commonStateTree);

      let publicOptions = new Map();
      let commonOptions = new Map();
      let industryOptions = new Map();

      const getOptions = (source, target) => {
        if (_.isEmpty(source)) {
          source = [];
        }
        for (const item of source) {
          const { children } = item;
          if (!Array.isArray(children) || children.length < 1) continue;
          if (children.length === 1) {
            let _target = target;
            const child = children[0];
            if ([1, 2, 3].includes(child.statusId)) _target = publicOptions;
            _target.set(
              child.statusId,
              {
                value: child.statusId,
                label: child.statusName,
                _desc: child.statusDesc,
                deprecated: child?.deprecated,
              },
            );
          }
          if (children.length > 1) {
            for (const child of children) {
              target.set(
                child.statusId,
                {
                  value: child.statusId,
                  label: child.statusName,
                  _desc: child.statusDesc,
                  deprecated: child?.deprecated,
                },
              );
            }
          }
        }
      };

      getOptions(payload.industry, industryOptions);
      getOptions(payload.standard, commonOptions);

      for (const [key, val] of industryOptions.entries()) {
        if (!commonOptions.has(key)) continue;
        publicOptions.push(val);
        commonOptions.delete(key);
        industryOptions.delete(key);
      }
      publicOptions = [...publicOptions.entries()].map(([, v]) => v);
      commonOptions = [...commonOptions.entries()].map(([, v]) => v);
      industryOptions = [...industryOptions.entries()].map(([, v]) => v);

      const stateTips = getTips(industry === 2 ? payload.industry : payload.standard);

      publicOptions.forEach(item => stateQueryOptions.push(item));
      if (industryOptions.length > 0) {
        stateQueryOptions.push({ value: -3, label: '行业化线索状态', isTitle: true });
        industryOptions.forEach(item => stateQueryOptions.push(item));
      }
      stateQueryOptions.push({ value: -2, label: '标准化线索状态', isTitle: true });
      commonOptions.forEach(item => stateQueryOptions.push(item));

      return {
        ...state,
        industry,
        industryStateTree,
        commonStateTree,
        stateQueryOptions,
        currentStateTree,
        stateTips,
        sourceIndustry: payload.industry || [],
        sourceCommon: payload.standard || [],
      };
    }
    case GlobalStateAction.SET_OA_USER:
      return { ...state, oaUser: action.payload };

    default:
      return state;
  }
};
