import { combineReducers, createStore } from 'redux';
import LeadsManageReducer from '../reducers/LeadsManageReducer';
import GlobalStateReducer from '../reducers/GlobalStateReducer';
import XundaStateReducer from '../reducers/XundaStateReducer';
import GroupListReducer from '../reducers/GroupListReducer';
import AccountListReducer from '../reducers/AccountListReducer';
import EmployeeListReducer from '../reducers/EmployeeListReducer';
import TagListReducer from '../reducers/TagListReducer';
import RecycleReducer from '../reducers/RecycleReducer';
import OutCallConfigReducer from '../reducers/OutCallConfigReducer';

export const store = createStore(
  combineReducers({
    leadsManage: LeadsManageReducer,
    global: GlobalStateReducer,
    xunda: XundaStateReducer,
    group: GroupListReducer,
    account: AccountListReducer,
    employee: EmployeeListReducer,
    tag: TagListReducer,
    recycle: RecycleReducer,
    outCallConfig: OutCallConfigReducer,
  }),
);
