/**
 * 外呼配置
 *
 * @param state
 * @param action
 */
import { OutCallConfigAction } from '../actions/OutCallConfigAction';

const defaultState = {
  /**
   * 外呼指定的城市列表
   * @type {{
   *   cityName: string,
   *   cityNameCn: string,
   * }[]}
   */
  cityList: [],
  /**
   * 中间号分配优先规则
   * default: SPECIFIED
   * - USER_LOCATION: 优先用户所在地
   * - SPECIFIED: 优先指定城市（仅网络电话支持）
   * @type {'SPECIFIED'|'USER_LOCATION'}
   */
  numAssignPriorityMode: 'USER_LOCATION',
  /**
   * 号码分配优先城市英文名称
   * default: ''
   * - [string]: 小程序中间号分配规则为优先指定城市时，设置的优先城市；
   * - [string]: 小程序中间号分配规则为优先用户所在地时，为PC端设置的优先城市；
   * - [string]: PC和小程序同时设置时，互相同步为相同的值；
   * - [string]: PC和小程序都未设置时，返回NULL；
   * @type {string}
   */
  numAssignPriorityCity: '',


  /** 自动批量外呼运行状态 */
  autoBatchRuning: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OutCallConfigAction.SET_CITY:
      return { ...state, cityList: action.payload };
    case OutCallConfigAction.SET_CONFIG:
      return {
        ...state,
        numAssignPriorityMode: action.payload.numAssignPriorityMode,
        numAssignPriorityCity: action.payload.numAssignPriorityCity,
      };
    case OutCallConfigAction.SET_AUTO_BATCH_CALL_RUNING:
      console.log('OutCallConfigAction action', action);
      return { ...state, autoBatchRuning: action.payload };

    default:
      return state;
  }
};
