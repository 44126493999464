import { GlobalStateAction } from '../actions/GlobalStateAction';

const defaultState = {
  tagList: [],
  tagStatus: 'init',
};

export default (state = defaultState, action) => {
  const { payload, type } = action;
  switch (type) {
    case GlobalStateAction.SET_TAG_LIST:
      return {
        ...state,
        tagList: payload.tagList || [],
        groupStatus: 'received',
      };
    case GlobalStateAction.TAG_LIST_SEARCHING:
      return {
        ...state,
        tagList: [],
        groupStatus: 'searching',
      };
    case GlobalStateAction.ADD_EDIT_TAG:
      return {
        ...state,
        tagList: [],
        groupStatus: 'init',
      };
    default:
      return state;
  }
};
