/**
 * 线索回收相关的全局状态
 */
import ACTION from '../actions/RecycleAction';

const defaultState = {
  enableClaim: false,
  enableAutoRecycle: false,
};

export default (state = defaultState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ACTION.UPDATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};
