export const GlobalStateAction = {
  SHOW_LOADING: 'SHOW_LOADING',
  HIDE_LOADING: 'HIDE_LOADING',

  SHOW_TIPS: 'SHOW_TIPS',
  HIDE_TIPS: 'HIDE_TIPS',

  SET_FXD_POOLS: 'SET_FXD_POOLS',

  SET_GROUP_LIST: 'SET_GROUP_LIST',
  GROUP_LIST_SEARCHING: 'GROUP_LIST_SEARCHING',

  SET_ACCOUNT_LIST: 'SET_ACCOUNT_LIST',
  ACCOUNT_LIST_SEARCHING: 'ACCOUNT_LIST_SEARCHING',
  ADD_EDIT_GROUP: 'ADD_EDIT_GROUP',

  SET_EMPLOYEE_LIST: 'SET_EMPLOYEE_LIST',
  EMPLOYEE_LIST_SEARCHING: 'EMPLOYEE_LIST_SEARCHING',
  ADD_EDIT_EMPLOYEE: 'ADD_EDIT_EMPLOYEE',

  SET_TAG_LIST: 'SET_TAG_LIST',
  TAG_LIST_SEARCHING: 'TAG_LIST_SEARCHING',
  ADD_EDIT_TAG: 'ADD_EDIT_TAG',

  SET_INDUSTRY: 'SET_INDUSTRY',
  SET_INDUSTRY_DATA: 'SET_INDUSTRY_DATA',
  INDUSTRY_INIT: 'INDUSTRY_INIT',
  INDUSTRY_SWITCH: 'INDUSTRY_SWITCH',
  SET_OA_USER: 'SET_OA_USER',
};
