import React from 'react';
import { Button, Dialog } from '@tencent/spaui';

import { RPCFactory } from '../../../utils/RequestUtil';
import particles from './particles';

export default class HomePage extends React.Component {
  componentDidMount() {
    window.particlesJS && window.particlesJS(particles, () => {
      console.log('callback - particles.js config loaded');
    });
    if (window.pageProps.token) {
      RPCFactory.sso
        .getLoginStateInfo()
        .done((data) => {
          this.setState({
            loginUrl: data.loginUrl,
            loginState: data.loginState,
            relatedAccountIdNamePairs: data.advertiserIdNamePairs,
            qq: data.qq,
            nickName: data.nickName,
          });
          if (data.loginState) {
            this.setState({ showSelectAccountDialog: true });
          }
        })
        .fail((ret, msg) => {
          window.alert(`服务器请求出错，出错信息：${msg}`);
        });
    } else {
      RPCFactory.init.getCSRF().done((data) => {
        console.log(data.toString());
        const tokenPairsArr = data.toString().match(/token: "[a-zA-Z0-9-]+"/g);
        const tokenPair = tokenPairsArr ? tokenPairsArr[0] : null;
        const tokenArr = tokenPair.match(/[a-zA-Z0-9-]+/g);
        const token = tokenArr ? tokenArr[1] : null;
        console.log(token);
        window.pageProps.token = token;
        RPCFactory.sso
          .getLoginStateInfo()
          .done((data) => {
            this.setState({
              loginUrl: data.loginUrl,
              loginState: data.loginState,
              relatedAccountIdNamePairs: data.advertiserIdNamePairs,
              qq: data.qq,
              nickName: data.nickName,
            });

            if (data.loginState) {
              this.setState({ showSelectAccountDialog: true });
            }
          })
          .fail((ret, msg) => {
            window.alert(`服务器请求出错，出错信息：${msg}`);
          });
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // 首页线条动态效果使用
    window.particlesJS && window.particlesJS(particles, () => {
      console.log('callback - particles.js config loaded');
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      loginUrl: '',
      loginState: false,
      relatedAccountIdNamePairs: [],
      qq: '',
      nickName: '',

      showSelectAccountDialog: false,
    };
  }

  /**
   * 根据当前登录状态，判断
   * 1、如果当前已登录，则展示弹窗，出现广告主选择页面
   * 2、如果当前未登录，则跳转到sso统一登录页面
   * @param e
   */
  onLogin = (e) => {
    RPCFactory.sso
      .getLoginStateInfo()
      .done((data) => {
        if (!data.loginState) {
          window.location.href = data.loginUrl;
        } else {
          this.setState({
            loginUrl: data.loginUrl,
            loginState: data.loginState,
            relatedAccountIdNamePairs: data.advertiserIdNamePairs,
            showSelectAccountDialog: true,
            qq: data.qq,
            nickName: data.nickName,
          });
        }
      })
      .fail((ret, msg) => {
        window.alert(`服务器请求出错，出错信息：${msg}`);
      });
  };

  onCloseSelectAccountDialog = (e) => {
    this.setState({ showSelectAccountDialog: false });
  };

  selectAccount = (accountId) => {
    window.location.href = `/advertiser/${accountId}`;
  };

  logout = () => {
    RPCFactory.sso
      .logout()
      .done((data) => {
        window.location.reload();
      })
      .fail((ret, msg) => {
        window.alert(`退出失败，错误信息：${msg}`);
      });
  };

  render() {
    const authuredIdNamePairs = [];
    const unAuthuredIdNamePairs = [];

    if (this.state.relatedAccountIdNamePairs) {
      this.state.relatedAccountIdNamePairs.map((idNamePair) => {
        if (idNamePair.name && idNamePair.name.length > 10) {
          idNamePair.cutName = `${idNamePair.name.substr(0, 10)}...`;
        } else {
          idNamePair.cutName = idNamePair.name;
        }

        if (idNamePair.authorizationStatus == 0) {
          authuredIdNamePairs.push(idNamePair);
        } else {
          unAuthuredIdNamePairs.push(idNamePair);
        }
      });
    }

    return (
      <div className="dashboard index-panel" id="particles-js">
        <div data-reactroot>
          {/* 头部*/}
          <div className="spaui-topnav spaui-topnav-white" title="线索管理平台">
            <div className="container">
              <div className="spaui-topnav-header" title="线索管理平台">
                <div className="spaui-topnav-left">
                  <a href="javascript:void(0)" className="spaui-topnav-brand logo"></a>
                  <a href="javascript:void(0)" className="spaui-topnav-title">
                    <span>线索管理平台</span>
                  </a>
                </div>
              </div>
              <div className="spaui-topnav-collapse-right">
                <div className="userinfo">
                  <div className="">
                    <Button
                      type="button"
                      className="spaui-button spaui-button-primary"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={this.onLogin}
                    >
                      {this.state.loginState ? '进入线索管理平台' : '登录'}
                    </Button>
                    {this.state.loginState ? (
                      <div className="user btn-group spaui-topnav-drop">
                        <button
                          type="button"
                          className="spaui-button spaui-button-sm btn-link spaui-topnav-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span>
                            <i className="spaui-icon ico-user">
                              <i></i>
                            </i>
                            <span className="menu-text">
                              {this.state.qq}/{this.state.nickName}
                            </span>
                            <i className="icon-darr" aria-hidden="true"></i>
                          </span>
                        </button>
                        <div className="spaui-topnav-menu">
                          <a onClick={this.logout} className="spaui-topnav-menu-item" href="javascript:;">
                            退出登录
                          </a>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
            <div className="banner-word">
              <div className="banner-title">一站式的线索管理利器</div>
              <p>
                致力于帮助广告主更高效精细的管理线索，深刻理解线索，
                <br />
                充分发挥线索价值，指导优化投放，提升ROI。
              </p>
              <Button className="spaui-button spaui-button-default" onClick={this.onLogin}>
                立即登录
              </Button>
            </div>
          </div>

          <Dialog
            show={this.state.showSelectAccountDialog}
            style={{
              width: '500px',
            }}
            type="simple"
            onClose={this.onCloseSelectAccountDialog}
            onCancel={this.onCloseSelectAccountDialog}
            title="请选择广告主账户"
            hideFooter={true}
          >
            <ul className="account-select">
              {authuredIdNamePairs.map(idNamePair => (
                <li onClick={event => this.selectAccount(idNamePair.uid)}>
                  <div className="user-name" title={idNamePair.name}>
                    {idNamePair.cutName}
                  </div>
                  <span className="id">{idNamePair.appId ? idNamePair.appId : idNamePair.uid}</span>
                </li>
              ))}
              {unAuthuredIdNamePairs.map(idNamePair => (
                <li>
                  <div className="user-name" title={idNamePair.name}>
                    {idNamePair.cutName}
                  </div>
                  <span className="id">
                    {idNamePair.appId ? idNamePair.appId : idNamePair.uid}
                    <span style={{ color: 'red' }}>(未授权)</span>
                  </span>
                </li>
              ))}
              {unAuthuredIdNamePairs.length == 0 && authuredIdNamePairs.length == 0 ? (
                '没有任何广告主权限'
              ) : unAuthuredIdNamePairs.length != 0 ? (
                <li>未授权账户请登录http://mp.weixin.qq.com确认授权！</li>
              ) : (
                ''
              )}
            </ul>
          </Dialog>
        </div>
        {/* 首页线条动态效果使用*/}
        <canvas
          className="particles-js-canvas-el"
          style={{ width: '100%', height: '100%' }}
          width="3584px"
          height="612px"
        ></canvas>
        {/* 首页线条动态效果使用*/}
      </div>
    );
  }
}
