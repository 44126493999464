/**
 * 针对讯达状态
 * @param state
 * @param action
 */
import { XundaStateAction } from '../actions/XundaStateAction';

const defaultState = {
  // 不同讯达对应的下拉框的实际名称，如：
  // 房讯达 -> 楼盘
  // 车讯达 -> 经销商
  status: 'init',
  poolName: '',
  pools: [],
};

export default (state = defaultState, action) => {
  const { payload, type } = action;
  switch (type) {
    case XundaStateAction.SET_FXD_POOL:
      return {
        ...state,
        status: 'received',
        poolName: payload.name,
        pools: payload.pool || [],
      };

    default:
      return state;
  }
};
