export const Common = {
  HTTP_HEADER: 'http://',

  HOST: 'leads.qq.com',

  LEADS_DOWNLOAD: '/api/v1/leads/list_download',
  LEADS_LOGS_DOWNLOAD: '/api/v2/operation_log/download',
  ASSOCIATE_DOWNLOAD: '/api/v1/leads/associate_list_download',
  LEADS_DOWNLOAD_FILEINFO: '/api/v2/leads/list_download/fileInfo',
  CONSULT_DOWNLOAD: '/api/v1/leads/consult_list_download',
  EMPLOYEE_DOWNLOAD: '/api/v1/employee/download',
  sidebar_auth_url: '/api/v1/wx_work/auth_url/1/qwsidebar',
  qw_auth_url: '/api/v1/wx_work/auth_url/1/customerList',

  /** 企微活码下载接口 */
  QW_DRAINAGE_DOWNLOAD: '/api/v1/wx_work_qr_code/download_list',

  LEADS_CALL_RECORD_DOWNLOAD: '/api/v1/leads/call_record_list_download',
  LEADS_PAY_RECORD_DOWNLOAD: '/api/v1/invalid_pay/download_detail',
  LEADS_ANALYSIS_DOWNLOAD_GROUP: '/api/v1/employee_statement/download_user_group_data',
  LEADS_ANALYSIS_DOWNLOAD_EMPLOYEE: '/api/v1/employee_statement/download_user_data',

  LEADS_DMP_DETAIL_UPLOAD_DOWNLOAD: '/api/v1/report_dmp_statement/detail_data_download',
  LEADS_ACTION_DETAIL_UPLOAD_DOWNLOAD: '/api/v1/report_dmp_action/detail_data_download',

  CUSTOMER_SERVICE_SCRIPT_UPLOAD_FILE: '/api/v1/customer_service_script/upload_file',


  DURATION_2_SEC: 2000,
  DURATION_1_SEC: 1000,
  // 下载文件提示最大行数 20000, 大概10MB
  MAX_DOWNLOAD_FILE_LINE: 20000,
  MAX_DOWNLOAD_FILE_TIPS: '已开始下载，当前文件较大，请耐心等待',
  BEGIN_DOWNLOAD_FILE_TIPS: '已开始下载，请耐心等待',
};

export const LeadsDataFilterShortcut = [
  { startRelativeDate: 0, endRelativeDate: 0, label: '今天' },
  { startRelativeDate: -1, endRelativeDate: -1, label: '昨天' },
  { startRelativeDate: -6, endRelativeDate: 0, label: '最近7天' },
  { startRelativeDate: -29, endRelativeDate: 0, label: '最近30天' },
  { startRelativeDate: 1 - (new Date().getDate()), endRelativeDate: 0, label: '当月' },
];

/** 房产行业线索洞察线索竞争度，1低2中3高 */
export const INSIGHT_COMPETEDEGREE_MAPPING = {
  1: '低',
  2: '中',
  3: '高',
};
